<script>
export default {
  page: {
    // title: 'หน้าว่าง',
    meta: [{name: 'description'}],
  },
  components: {
  }, //
  props: {
    arrlocation: {
      type: Array,
      default: () => {
        return { message: 'no obj'}
      },
    },
  },
  data() {
    return {
      me: this.arrlocation,
      intervalid1: null,
    }
  },
  watch: {},
  beforeMount() {},
  mounted() {},

  methods: {
    show(data){
      this.arrlocation = data
      //// console.log (self.arrlocation);
    },
  },
}
</script>

<template>
  <ul class="list-unstyled activity-wid">
    <!-- v-for="(option,index) in offer_provider_data" -->
    <li v-for="(option,index) in arrlocation" :key="index" class="activity-list">
      <b-row>
        <div class="activity-icon avatar-xs">
          <span class="avatar-title bg-soft-primary text-primary rounded-circle">
            <i :class="`ri-time-fill`" />
          </span>
        </div>
        <b-col>
          <span v-if="index == 0">
            ต้นทาง  
          </span>
          <span v-else-if="index > 0 && arrlocation.length <= 2">
            ปลายทาง
          </span>
          <span v-else-if="index > 0 && arrlocation.length > 2">
            ปลายทาง {{ index }}
          </span>
          <br />
          <span class="font-color-blue">
            {{ option.name }}<!--  --> <!--  plan_data_selcet.rawData.depart_address1 -->
          </span>
          <br />
          <span class="font-color-blue">
            {{ option.date_appointment | moment("dd DD/MM/YYYY HH:mm") }} <!--  --> <!-- plan_data_selcet.rawData.depart_date | moment("DD/MM/YYYY HH:mm") -->
          </span>
        </b-col>
      </b-row>
    </li>
  </ul>
</template>
<style>
  .avatar-title {
    /* background-color: #fff !important; */
    background-color: hsla(197, 100%, 89%, 0) !important;
  }
</style>